import React from 'react';
import LoginForm from './LoginForm';

const LoginPage = ({ onLoginSuccess }) => {
  return (
    <div className="min-h-screen bg-black bg-cover bg-center text-white flex flex-col justify-center items-center" style={{ backgroundImage: "url('/img/bg-1.jpg')" }}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h1 className="text-3xl font-bold text-center mb-6 text-blue-800">Zaloguj się</h1>
        <LoginForm onLoginSuccess={onLoginSuccess} />
      </div>
    </div>
  );
};

export default LoginPage;
