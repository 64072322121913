// Header.js
import React from "react";
import { format } from "date-fns";
import { pl } from 'date-fns/locale';
import { FaCalendarAlt } from "react-icons/fa";

const Header = () => {
  const today = new Date();
  const formattedDate = format(today, "EEEE, d MMMM yyyy", { locale: pl });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <header className="text-center mb-6">
      <h1 className="text-4xl font-bold mb-2 text-gray-100 dark:text-gray-100">
        
      </h1>
      <p className="text-gray-800 dark:text-gray-100 font-light text-3xl tracking-wider uppercase">
      Budżety klientów
      </p>
      <div className="flex justify-center items-center mt-4">
        <FaCalendarAlt className="text-gray-200 dark:text-gray-200 mr-2" />
        <p className="text-gray-800 dark:text-gray-200 text-lg">
          {capitalizeFirstLetter(formattedDate)}
        </p>
      </div>
    </header>
  );
};

export default Header;
