// src/auth/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, user, requiredRoles, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (requiredRoles && user) {
    if (!requiredRoles.includes(user.role)) {
      // Redirect to an unauthorized page
      return <Navigate to="/unauthorized" />;
    }
  }

  if (requiredRoles && !user) {
    // User data not loaded yet
    return <div>Loading...</div>;
  }

  return children;
};

export default PrivateRoute;
