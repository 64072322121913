// AccountTable.js
import React from "react";
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaSortNumericDown,
  FaSortNumericUp,
} from "react-icons/fa";

const AccountTable = ({
  accounts,
  sortOption,
  sortOrder,
  handleSortChange,
}) => {
  const getIcon = (option) => {
    if (sortOption !== option) return null;
    if (sortOrder === "asc") {
      return option === "spend" ? (
        <FaSortNumericDown className="inline ml-1" />
      ) : (
        <FaSortAlphaDown className="inline ml-1" />
      );
    } else {
      return option === "spend" ? (
        <FaSortNumericUp className="inline ml-1" />
      ) : (
        <FaSortAlphaUp className="inline ml-1" />
      );
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full text-left border-collapse">
        <thead>
          <tr>
            <th className="px-4 py-2 text-gray-600 dark:text-gray-400 text-center">
              #
            </th>
            <th
              className="px-4 py-2 cursor-pointer text-gray-600 dark:text-gray-400"
              onClick={() => handleSortChange("name")}
            >
              Nazwa konta {getIcon("name")}
            </th>
            <th
              className="px-4 py-2 cursor-pointer text-gray-600 dark:text-gray-400"
              onClick={() => handleSortChange("id")}
            >
              ID Konta {getIcon("id")}
            </th>
            <th
              className="px-4 py-2 cursor-pointer text-gray-600 dark:text-gray-400"
              onClick={() => handleSortChange("spend")}
            >
              Wydatki (poprzedni miesiąc) {getIcon("spend")}
            </th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account, index) => (
            <tr
              key={account.account_id}
              className="border-t border-gray-600 dark:border-gray-600"
            >
              <td className="px-4 py-2 text-center text-gray-800 dark:text-gray-100">
                {index + 1}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-gray-100">
                {account.account_name}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-gray-100">
                {account.account_id}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-gray-100">
                {account.spend_previous_month} PLN
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountTable;
