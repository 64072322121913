// Dashboard.js
import React, { useState, useEffect } from "react";
import Header from "./Header";
import Controls from "./Controls";
import AccountCard from "./AccountCard";
import AccountTable from "./AccountTable";
import { fetchAdAccounts } from "../../auth/auth";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

// Import necessary modules
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const CACHE_KEY = "cachedAccounts";
const CACHE_TIMESTAMP_KEY = "cachedAccountsTimestamp";
const CACHE_EXPIRATION_MS = 24 * 60 * 60 * 1000; // 24 hours (optional)

const Dashboard = () => {
  const [accounts, setAccounts] = useState([]);
  const [sortedAccounts, setSortedAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCardView, setIsCardView] = useState(true);
  const [sortOption, setSortOption] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  // Load cached data on component mount
  useEffect(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);

    if (cachedData && cachedTimestamp) {
      const now = Date.now();
      const age = now - parseInt(cachedTimestamp, 10);

      if (age < CACHE_EXPIRATION_MS) {
        // Data is fresh, load from cache
        const parsedData = JSON.parse(cachedData);
        setAccounts(parsedData);
        filterAndSortAccounts(parsedData, sortOption, sortOrder, searchQuery);
        console.log("Loaded accounts from cache");
      } else {
        // Data is stale, remove from cache
        localStorage.removeItem(CACHE_KEY);
        localStorage.removeItem(CACHE_TIMESTAMP_KEY);
        console.log("Cached data is stale, please refresh");
      }
    } else {
      console.log("No cached data found");
    }
  }, []);

  // Update sortedAccounts whenever dependencies change
  useEffect(() => {
    filterAndSortAccounts(accounts, sortOption, sortOrder, searchQuery);
  }, [accounts, sortOption, sortOrder, searchQuery]);

  const handleFetchData = async () => {
    const cachedData = localStorage.getItem(CACHE_KEY);

    if (cachedData) {
      const confirmRefresh = window.confirm(
        "Dane są już w pamięci podręcznej. Pobranie nowych danych może potrwać kilka sekund. Czy na pewno chcesz kontynuować?"
      );
      if (!confirmRefresh) {
        return;
      }
    }

    setLoading(true);
    const toastId = toast.loading("Trwa pobieranie danych...");

    try {
      const response = await fetchAdAccounts();
      if (response.status === "success") {
        setAccounts(response.data);
        filterAndSortAccounts(response.data, sortOption, sortOrder, searchQuery);
        // Cache the data with a timestamp
        localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
        localStorage.setItem(CACHE_TIMESTAMP_KEY, Date.now().toString());
        console.log("Fetched accounts from API and updated cache");
        toast.update(toastId, {
          render: "Dane zostały pomyślnie pobrane.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        console.error("Error:", response.message);
        toast.update(toastId, {
          render: "Błąd podczas pobierania danych.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.update(toastId, {
        render: "Błąd podczas pobierania danych.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const filterAndSortAccounts = (accountsToProcess, option, order, query) => {
    let filteredAccounts = accountsToProcess;

    if (query) {
      filteredAccounts = filteredAccounts.filter((account) =>
        account.account_name.toLowerCase().includes(query.toLowerCase())
      );
    }

    const sorted = [...filteredAccounts].sort((a, b) => {
      let aValue, bValue;
      switch (option) {
        case "name":
          aValue = a.account_name.toLowerCase();
          bValue = b.account_name.toLowerCase();
          break;
        case "id":
          aValue = a.account_id;
          bValue = b.account_id;
          break;
        case "spend":
          aValue = parseFloat(a.spend_previous_month);
          bValue = parseFloat(b.spend_previous_month);
          break;
        default:
          return 0;
      }
      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
    setSortedAccounts(sorted);
  };

  const handleSortChange = (option) => {
    const newOrder =
      sortOption === option && sortOrder === "asc" ? "desc" : "asc";
    setSortOption(option);
    setSortOrder(newOrder);
    // No need to call filterAndSortAccounts here; useEffect will handle it
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // No need to call filterAndSortAccounts here; useEffect will handle it
  };

  // New function to export data to Excel
  const exportToExcel = () => {
    if (sortedAccounts.length === 0) {
      toast.warning("Brak danych do eksportowania.");
      return;
    }

    // Prepare data for Excel
    const dataForExcel = sortedAccounts.map((account) => ({
      "Nazwa konta": account.account_name,
      "ID Konta": account.account_id,
      "Wydatki (poprzedni miesiąc)": account.spend_previous_month + " PLN",
      "Nazwa klienta": account.client_name || "",
      "ID klienta": account.client_id || "",
    }));

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dane kont");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the file
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "dane_kont.xlsx");

    toast.success("Dane zostały wyeksportowane do pliku Excel.");
  };

  return (
    <div
      className="h-screen bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100 bg-cover bg-center bg-fixed overflow-y-auto relative"
      //style={{ backgroundImage: "url('/img/landing.jpg')" }}
    >
      {/* Optional Overlay */}
      {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
      <div className="relative p-6">
        <Header />
        <Controls
          loading={loading}
          isCardView={isCardView}
          handleFetchData={handleFetchData}
          setIsCardView={setIsCardView}
          sortOption={sortOption}
          sortOrder={sortOrder}
          handleSortChange={handleSortChange}
          searchQuery={searchQuery} // Pass searchQuery to Controls
          handleSearchChange={handleSearchChange} // Pass handleSearchChange to Controls
          exportToExcel={exportToExcel} // Pass export function to Controls
        />
        {loading ? (
          <div className="flex justify-center items-center h-40">
            <ClipLoader color="gray" size={50} />
          </div>
        ) : sortedAccounts.length > 0 ? (
          isCardView ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {sortedAccounts.map((account) => (
                <AccountCard key={account.account_id} account={account} />
              ))}
            </div>
          ) : (
            <AccountTable
              accounts={sortedAccounts}
              sortOption={sortOption}
              sortOrder={sortOrder}
              handleSortChange={handleSortChange}
            />
          )
        ) : (
          <div className="text-center mt-10 text-gray-600 dark:text-gray-400">
            <p>Brak danych lub brak wyników dla wyszukiwania.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
