// AccountCard.js
import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const AccountCard = ({ account }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="relative bg-white dark:bg-gray-800 bg-opacity-30 dark:bg-opacity-30 rounded-2xl shadow-lg p-6 backdrop-filter backdrop-blur-lg border
     border-gray-200 dark:border-gray-700">
      <h2 className="text-2xl font-light mb-2 text-gray-800 dark:text-gray-100">
        {account.account_name}
      </h2>
      <p className="text-gray-800 dark:text-gray-100 mb-1 font-light">
        ID Konta: {account.account_id}
      </p>
      <p className="text-gray-800 dark:text-gray-100 mb-4 text-3xl font-semibold">
        {account.spend_previous_month} PLN
      </p>
      <button
        onClick={() => setShowDetails((prev) => !prev)}
        className="flex items-center px-4 py-2 bg-white dark:bg-gray-700 bg-opacity-30 dark:bg-opacity-30 hover:bg-opacity-50 dark:hover:bg-opacity-50 text-gray-800 dark:text-gray-100 rounded-full transition duration-300 backdrop-filter backdrop-blur-md"
      >
        {showDetails ? "Ukryj szczegóły" : "Pokaż szczegóły"}
        {showDetails ? (
          <FaChevronUp className="ml-2" />
        ) : (
          <FaChevronDown className="ml-2" />
        )}
      </button>
      {showDetails && (
        <div className="mt-4 text-gray-800 dark:text-gray-100">
          <h3 className="font-medium text-lg mb-2">Kampanie:</h3>
          <ul className="space-y-2">
            {account.campaigns.map((campaign) => (
              <li key={campaign.id} className="flex items-center space-x-2">
                <span className="font-light">{campaign.name}</span>
                <span
                  className={`text-sm font-bold px-2 py-1 rounded-full ${
                    campaign.status === "ACTIVE"
                      ? "bg-green-500 text-white"
                      : "bg-red-500 text-white"
                  }`}
                >
                  {campaign.status === "ACTIVE" ? "Aktywna" : "Wstrzymana"}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountCard;
