// src/components/Unauthorized.js

import React from 'react';

const Unauthorized = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-400">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <h1 className="text-3xl font-bold mb-6 text-red-600">Access Denied</h1>
        <p className="text-lg mb-4">You do not have permission to view this page.</p>
        <p className="text-sm text-gray-600">Please contact the administrator if you believe this is an error.</p>
      </div>
    </div>
  );
};

export default Unauthorized;

