import React from 'react';
import { Link } from 'react-router-dom';
import { 
  FaHome, 
  FaLongArrowAltRight
} from 'react-icons/fa';
const MainScreen = () => {
  return (
    <div
      className="min-h-screen bg-black bg-cover bg-center text-white flex flex-col justify-between"
      style={{ backgroundImage: "url('/img/landing.jpg')" }}
    >
      {/* Header */}
      <header className="w-full px-6 py-4 bg-black bg-opacity-70">
        <h1 className="text-4xl font-extrabold text-sky-100">Aurora CMS</h1>
      </header>

      {/* Hero Section */}
      <main className="flex flex-col items-center justify-center flex-1 text-center px-4">

        <p className="text-lg sm:text-xl text-gray-200 max-w-2xl mb-8 bg-black p-2 rounded-xl">
          Your next-generation internal tool for online marketing management.
        </p>

        <Link
            to="/dashboard"
            className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
          >
            
            <button className="px-8 py-3 text-lg font-semibold rounded-lg bg-black hover:bg-purple-700 transition duration-300">
            <FaLongArrowAltRight className="text-3xl" /> Start
        </button>
          </Link>        
      </main>

      {/* Footer */}
      <footer className="w-full text-center py-6 bg-black bg-opacity-70">
        <p className="text-gray-500">
          © 2024 Aurora CMS. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default MainScreen;
