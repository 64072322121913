// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './components/pages/MainScreen';
import LoginPage from './components/pages/LoginPage';
import Dashboard from './components/pages/Dashboard';
import Navbar from './components/Navbar';
import Spinner from './components/Spinner';
import Unauthorized from './components/Unauthorized';
import PrivateRoute from './auth/PrivateRoute';
import { api } from './auth/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (accessToken) => {
    localStorage.setItem('token', accessToken);
    setToken(accessToken);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (token) {
        try {
          const response = await api.get('/profile');
          console.log('Fetched user profile:', response.data);
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user profile:', error);
          handleLogout();
        }
      } else {
        setUser(null);
      }
    };
    fetchUserProfile();
  }, [token]);

  if (token && !user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Router>
      <Navbar token={token} onLogout={handleLogout} user={user} />
      <div className="bg-gradient-to-r from-bluey-300 to-bluey-600 min-h-screen flex flex-col">
        <Routes>
          {/* Public Route */}
          <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
          <Route path="/" element={<MainScreen />} />
          {/* Protected Route */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isAuthenticated={!!token} user={user}>
                <Dashboard user={user} />
              </PrivateRoute>
            }
          />

          {/* Unauthorized Route */}
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
}

export default App;
