// SortControls.js
import React from "react";
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaSortNumericDown,
  FaSortNumericUp,
} from "react-icons/fa";

const SortControls = ({ sortOption, sortOrder, handleSortChange }) => {
  const getIcon = (option) => {
    if (sortOption !== option) return null;
    if (sortOrder === "asc") {
      return option === "spend" ? (
        <FaSortNumericDown className="inline ml-2" />
      ) : (
        <FaSortAlphaDown className="inline ml-2" />
      );
    } else {
      return option === "spend" ? (
        <FaSortNumericUp className="inline ml-2" />
      ) : (
        <FaSortAlphaUp className="inline ml-2" />
      );
    }
  };

  return (
    <div className="flex space-x-2 text-gray-900 dark:text-gray-300">
      <button
        onClick={() => handleSortChange("name")}
        className="flex items-center px-4 py-2 bg-transparent border border-purple-500  hover:bg-purple-500 hover:text-white rounded-full shadow transition duration-300"
      >
        <span>Nazwa</span>
        {getIcon("name")}
      </button>
      <button
        onClick={() => handleSortChange("id")}
        className="flex items-center px-4 py-2 bg-transparent border border-purple-500  hover:bg-purple-500 hover:text-white rounded-full shadow transition duration-300"
      >
        <span>ID Konta</span>
        {getIcon("id")}
      </button>
      <button
        onClick={() => handleSortChange("spend")}
        className="flex items-center px-4 py-2 bg-transparent border border-purple-500  hover:bg-purple-500 hover:text-white rounded-full shadow transition duration-300"
      >
        <span>Wydatki</span>
        {getIcon("spend")}
      </button>
    </div>
  );
};

export default SortControls;
