// Controls.js
import React from "react";
import {
  FaTable,
  FaThLarge,
  FaSyncAlt,
  FaSearch,
  FaFileExcel, // Import Excel icon
} from "react-icons/fa";
import SortControls from "./SortControls";

const Controls = ({
  loading,
  isCardView,
  handleFetchData,
  setIsCardView,
  sortOption,
  sortOrder,
  handleSortChange,
  searchQuery,
  handleSearchChange,
  exportToExcel, // Receive the export function
}) => {
  return (
    <div className="flex flex-col sm:flex-row justify-center items-center flex-wrap space-y-4 sm:space-y-0 sm:space-x-4 mb-6 text-gray-900 dark:text-gray-300">
      {/* Refresh Button */}
      <button
        onClick={handleFetchData}
        className="flex items-center px-6 py-3 bg-transparent border border-blue-500  hover:bg-blue-500 hover:text-white rounded-full shadow transition duration-300"
        disabled={loading}
      >
        <FaSyncAlt className="mr-2" />
        {loading ? "Pobieranie..." : "Odśwież dane"}
      </button>

      {/* View Toggle Button */}
      <button
        onClick={() => setIsCardView(!isCardView)}
        className="flex items-center px-6 py-3 bg-transparent border border-yellow-500  hover:bg-yellow-500 hover:text-white rounded-full shadow transition duration-300"
      >
        {isCardView ? (
          <>
            <FaTable className="mr-2" />
            Widok tabeli
          </>
        ) : (
          <>
            <FaThLarge className="mr-2" />
            Widok kart
          </>
        )}
      </button>

      {/* Sort Controls */}
      <SortControls
        sortOption={sortOption}
        sortOrder={sortOrder}
        handleSortChange={handleSortChange}
      />

      {/* Search Input */}
      <div className="flex items-center px-4 py-2 bg-transparent border border-purple-500 text-gray-900 dark:text-gray-300 rounded-full shadow transition duration-300">
        <FaSearch className=" mr-2" />
        <input
          type="text"
          placeholder="Szukaj konta"
          value={searchQuery}
          onChange={handleSearchChange}
          className="bg-transparent focus:outline-none text-gray-800 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400"
        />
      </div>

      {/* Export to Excel Button */}
      <button
        onClick={exportToExcel}
        className="flex items-center px-6 py-3 bg-transparent border border-green-500  hover:bg-green-500 hover:text-white rounded-full shadow transition duration-300"
      >
        <FaFileExcel className="mr-2" />
        Eksportuj do Excel
      </button>
    </div>
  );
};

export default Controls;
